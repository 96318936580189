<template>
  <travio-center-container grid-width="1/2" :pageTitle="'Edit Supplier - ' + form.supplierName ">
    <vx-card>
      <vs-tabs v-model="tabIndex" class="tabs-shadow-none">
        <vs-tab icon-pack="feather" icon="icon-settings" label="General Info">

          <vs-input class="w-full mt-4" label="Supplier Name*" v-model="form.supplierName" v-validate="'required|max:25'" name="Supplier Name" />
          <span class="text-danger text-sm">{{ errors.first('Supplier Name') }}</span>

          <div class="mt-4">
            <label class="text-sm">Product Type*</label>
            <v-select :value="selectedProductType" append-to-body @input="setSelectedProductType" v-validate="'required'" :options="productTypeOptions" name="Product Type" />
            <span class="text-danger text-sm">{{ errors.first('Product Type') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm">API Type*</label>
            <v-select :value="selectedApiType" append-to-body @input="setSelectedApiType" v-validate="'required'" :options="apiTypeOptions" name="API Type"/>
            <span class="text-danger text-sm">{{ errors.first('API Type') }}</span>
          </div>
          <vs-input class="w-full mt-4" label="Endpoint URL" v-model="form.endpointUrl" v-validate="'max:500'" name="Endpoint URL" />
          <span class="text-danger text-sm">{{ errors.first('Endpoint URL') }}</span>
          <vs-input class="w-full mt-4" label="Docs URL" v-model="form.docsUrl" v-validate="'max:500'" name="Docs URL" />
          <span class="text-danger text-sm">{{ errors.first('Docs URL') }}</span>
          <vs-input class="w-full mt-4" label="Status URL" v-model="form.statusUrl" v-validate="'max:500'" name="Status URL" />
          <span class="text-danger text-sm">{{ errors.first('Status URL') }}</span>
        </vs-tab>
        
        <vs-tab icon-pack="feather" icon="icon-users" label="Contacts">
          <vs-input class="w-full mt-4" label="Tech Contact" v-model="form.techContact" v-validate="'max:500'" name="Tech Contact" />
          <span class="text-danger text-sm">{{ errors.first('Tech Contact') }}</span>
          <vs-input class="w-full mt-4" label="Account Contact" v-model="form.accountContact" v-validate="'max:500'" name="Account Contact" />
          <span class="text-danger text-sm">{{ errors.first('Account Contact') }}</span>
          <vs-input class="w-full mt-4" label="Post Book Contact" v-model="form.postBookContact" v-validate="'max:500'" name="Post Book Contact" />
          <span class="text-danger text-sm">{{ errors.first('Post Book Contact') }}</span>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-lock" label="Credentials">
          <vs-input class="w-full mt-4" label="Username" v-model="form.username" v-validate="'max:25'" name="Username" />
          <span class="text-danger text-sm">{{ errors.first('Tech Contact') }}</span>
          <vs-input class="w-full mt-4" type="password" label="Password" v-model="form.password" v-validate="'max:25'" name="Password" />
        </vs-tab>
        
        <vs-tab icon-pack="feather" icon="icon-code" label="Settings">
          <supplier-settings :supplierId="supplierId" />
        </vs-tab>
      </vs-tabs>
      
      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'
import SupplierSettings from './SupplierSettings.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    SupplierSettings
  },
  props: { 
    supplierId: { required: true },
    tabIndexProp: { required: false, default: 0, type: Number }
  },
  data () {
    return {
      form: new Form({
        supplierName: '',
        productTypeId: 0,
        apiTypeId: 0,
        
        endpointUrl: null,
        docsUrl: null,
        statusUrl: null,

        username: null,
        password: null,

        techContact: null,
        accountContact: null,
        postBookContact: null,

      }),
      selectedProductType: null,
      selectedApiType: null,
      productTypeOptions: [],
      apiTypeOptions: [],
      tabIndex: 0
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    this.tabIndex = this.tabIndexProp
  },
  mounted () {
    
    this.$http.get(`api/admin/suppliers/lookups`)
    .then(response => {
      this.productTypeOptions = response.data.productTypeOptions
      this.apiTypeOptions = response.data.apiTypeOptions

      this.$http.get(`api/admin/suppliers/${this.supplierId}`)
      .then(response => {
        this.form = new Form(Object.assign(this.form.data(), response.data))
        this.selectedProductType = this.productTypeOptions.find(x => x.code == response.data.productTypeId) || null
        this.selectedApiType = this.apiTypeOptions.find(x => x.code == response.data.apiTypeId) || {};
      })
      .catch(err => console.error(err) )
      
    })
    .catch(err => console.error(err) )
  },
  beforeDestroy () {
  },
  methods: {
    setSelectedProductType (value) {
      this.form.productTypeId = value ? value.code : null
      this.selectedProductType = value;
    },
    setSelectedApiType (value) {
      this.form.apiTypeId = value ? value.code : null
      this.selectedApiType = value;
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {          
          this.$vs.loading()
          this.$http.put(`api/admin/suppliers/${this.supplierId}`, this.form.data())
          .then(response => {
            this.$_notifySuccess('Supplier successfully added.')
            this.$router.push({ name: 'admin-supplier' })
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        }
      })
    },
    handleCancel () {
      this.$router.push({ name: 'admin-supplier' })
    }
  }
}
</script>

<style>

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }

  .fixed-height-supplier-edit {
    height: calc(var(--vh, 1vh) * 100 - 38rem);
    /* overflow-y: hidden; */
    overflow-x: hidden;
    position: relative;
  }
</style>